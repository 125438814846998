import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, IconButton, Tab } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { changeMember, searchUserByCitizenId } from "../../../../api/myCompany";
import {
    getRequestedServicesCitizenIDWithAllCompany,
    getRequestedServicesCitizenIDWithAllCompanyMembers,
    getRequestedServicesCitizenIDWithOutCompany,
} from "../../../../api/RequestedServiceList";
import CenterLoading from "../../../../components/CenterLoading/CenterLoading";
import RequestCard from "../../../../components/RequestCard/RequestCard";
import Select from "../../../../components/Select/Select";
import TextField from "../../../../components/TextField/TextField";
import COLORS from "../../../../theme/Colors";
import FONTS_FAMILY from "../../../../theme/FontsFamily";
import { InputFilter, MediumHeightDivider, Row, RowMedia, SmallHeightDivider, StyledPagination } from "../../../../theme/Styles";
import { requestFilterTypes } from "../../MyDeskConstants";
import { ListContainer, SectionTitle } from "../../styles/MyDeskStyles";
import DialogChangeApplicant from "../Company/components/DialogChangeApplicant";

function MyRequests({ rolBase }) {
    const history = useHistory();
    const initialDataCompany = {
        value: 0,
        label: "Todas las empresas",
    };
    const initialDataApplicant = {
        value: 0,
        label: "Todos los solicitantes",
    };
    const finishData = {
        value: 0,
        label: "Sin registros",
    };
    const { companyID, citizenID } = useSelector((state) => state.authReducer);

    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData(["userData"]);

    const [requestType, setRequestType] = useState(1);
    const [requestForCompany, setRequestForCompany] = useState([]);
    const [requestForCompanyId, setRequestForCompanyId] = useState(0);
    const [requestForApplicant, setRequestForApplicant] = useState([]);
    const [requestForApplicantId, setRequestForApplicantId] = useState(0);

    const [companySelected, setCompanySelected] = useState(null);
    const [requestName, setRequestName] = useState("");
    const [requestNameCompany, setRequestNameCompany] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageCompany, setCurrentPageCompany] = useState(1);
    const [stringFilter, setStringFilter] = useState("");
    const [stringFilterCompany, setStringFilterCompany] = useState("");
    const [requestListByCompanyFilter, setRequestListByCompanyFilter] = useState([]);
    const [requestCode, setRequestCode] = useState();

    const titleRef = useRef(null);

    const [claimModalVisible, setClaimModalVisible] = useState(false);
    const [searchUser, setSearchUser] = useState(null);
    const [value, setValue] = useState("1");

    const mutationSearchUserById = useMutation(searchUserByCitizenId);
    const mutationChangeMember = useMutation(changeMember);

    const { data: requestsList, isLoading: requestListIsLoading } = useQuery(
        ["requestsList", requestType, stringFilter, currentPage, value],
        () => getRequestedServicesCitizenIDWithOutCompany(userData.payload.citizen_id, currentPage, requestType, stringFilter),
        {
            staleTime: 60000,
        }
    );

    const { data: requestListByCompany, isLoading: requestListByCompanyIsLoading } = useQuery(
        ["requestListByCompany", requestType, stringFilterCompany, currentPageCompany, value, requestForApplicantId, requestForCompanyId],
        () =>
            getRequestedServicesCitizenIDWithAllCompany(
                citizenID,
                currentPageCompany,
                requestType,
                stringFilterCompany,
                requestForApplicantId,
                requestForCompanyId
            ),
        {
            staleTime: 60000,
        }
    );

    const { data: requestListByCompanyMembers, isLoading: requestListByCompanyMembersIsLoading } = useQuery(
        ["requestListByCompanyMembers"],
        () => getRequestedServicesCitizenIDWithAllCompanyMembers(citizenID),
        {
            cacheTime: 0, // Deshabilita la caché
            staleTime: 0, // Opcional: asegura que los datos siempre se marquen como obsoletos
        }
    );

    useEffect(() => {
        if (requestForCompanyId != 0) {
            setCurrentPageCompany(1);
        } else if (requestForApplicantId != 0) {
            setCurrentPage(1);
        }
    }, [requestForCompanyId, requestForApplicantId]);

    useEffect(() => {
        if (requestListByCompany?.data?.length > 0 && requestListByCompanyMembers?.payload?.companies?.length > 0 && value === "2") {
            setRequestForCompany(() => [initialDataCompany, ...requestListByCompanyMembers?.payload?.companies]);
            setRequestForApplicant(() => [initialDataApplicant, ...requestListByCompanyMembers?.payload?.members]);
        }
    }, [requestListByCompany, value]);

    useEffect(() => {
        setRequestForCompanyId(0);
        setRequestListByCompanyFilter([]);
    }, [requestType]);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
        setRequestType(1);
    };

    const searchUserById = (formData) => {
        mutationSearchUserById.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    setSearchUser(data.payload);
                    // refresh cache of searchUserId
                    queryClient.invalidateQueries("searchUserId");
                } else {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "info",
                    });
                }
            },
        });
    };

    const handleTypeChange = (value) => {
        handlePageChange(1);
        setRequestType(value.target.value);
        setRequestName("");
        titleRef.current.scrollIntoView();
    };

    const handleTypeChangeCompany = (value) => {
        setRequestForCompanyId(value.target.value);
    };

    const handleTypeChangeApplicant = (value) => {
        setRequestForApplicantId(value.target.value);
    };

    const handlePageChange = (value, type) => {
        if (type == "personal") {
            setCurrentPage(value);
        } else {
            setCurrentPageCompany(value);
        }
        titleRef.current.scrollIntoView();
    };

    const handleStringFilter = (type) => {
        if (stringFilter === 0 || stringFilterCompany === 0) handlePageChange(1, type);
        if (type == "personal") {
            setStringFilter(requestName);
        } else {
            setStringFilterCompany(requestNameCompany);
        }
    };

    const handleRemoveFilters = (type) => {
        if (type == "personal") {
            setStringFilter("");
            setRequestName("");
        } else {
            setStringFilterCompany("");
            setRequestNameCompany("");
        }
    };

    const handleClaimModalVisibility = () => {
        setSearchUser(null);
        setClaimModalVisible(!claimModalVisible);
    };

    const deleteFoundUser = () => {
        setSearchUser(null);
    };

    const handlerChangeOwner = (citizenID) => {
        changeOwner({ request_code: requestCode, new_citizen_id: citizenID, company_id: companySelected });
    };

    const changeOwner = (formData) => {
        mutationChangeMember.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setSearchUser(null);
                            setRequestCode(null);
                            handleClaimModalVisibility();
                        }
                    });
                    // refresh cache of inviteUser
                    queryClient.invalidateQueries("requestListByCompany");
                } else {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "info",
                    });
                }
            },
        });
    };

    if (requestListIsLoading || requestListByCompanyIsLoading || requestListByCompanyMembersIsLoading) return <CenterLoading />;

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <MediumHeightDivider />
            <TabContext value={value}>
                <Box
                    sx={{
                        marginLeft: 3,
                        marginRight: 3,
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <TabList
                        TabIndicatorProps={{ style: { background: COLORS.secondary } }}
                        onChange={handleChangeTabs}
                        aria-label="lab API tabs example"
                    >
                        <Tab
                            label="Personal"
                            style={{
                                color: value === "1" ? COLORS.secondary : COLORS.secondaryOpa,
                                fontFamily: FONTS_FAMILY.regular.body,
                                fontWeight: "bold",
                                fontSize: 18,
                            }}
                            value="1"
                        />
                        <Tab
                            label="Empresa"
                            style={{
                                color: value === "2" ? COLORS.secondary : COLORS.secondaryOpa,
                                fontFamily: FONTS_FAMILY.regular.body,
                                fontWeight: "bold",
                                fontSize: 18,
                            }}
                            value="2"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Fade right>
                        <RowMedia style={{ justifyContent: "space-between" }}>
                            <Row style={{ alignItems: "center" }}>
                                <SectionTitle ref={titleRef}>
                                    {`Solicitudes ${requestFilterTypes
                                        .find((type) => type.value === Number(requestType))
                                        ?.label?.toLowerCase()}`}
                                </SectionTitle>
                            </Row>
                            <Select
                                title={"Filtro por status"}
                                id="filterType"
                                data={requestFilterTypes}
                                value={requestType}
                                onChange={handleTypeChange}
                                disableEmptyValue
                            />
                        </RowMedia>
                        <div style={{ height: "5px" }} />

                        <InputFilter style={{ position: "relative" }}>
                            <TextField
                                placeholder="Nombre o número de solicitud"
                                type="text"
                                id="requestID"
                                value={requestName}
                                onChange={(e) => setRequestName(e.target.value)}
                            />
                            {stringFilter.length > 0 ? (
                                <div style={{ width: "40px", position: "absolute", zIndex: 100, top: 20, right: 10 }}>
                                    <IconButton onClick={() => handleRemoveFilters("personal")} aria-label="delete">
                                        <CloseIcon titleAccess="Eliminar filtro" color="error" sx={{ fontSize: "1.2em" }} />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ width: "40px", position: "absolute", zIndex: 100, top: 20, right: 10 }}>
                                    <IconButton onClick={() => handleStringFilter("personal")} aria-label="delete">
                                        <SearchIcon titleAccess="buscar" color="action" sx={{ fontSize: "1.2em" }} />
                                    </IconButton>
                                </div>
                            )}
                        </InputFilter>

                        <ListContainer>
                            {requestsList?.data?.map((request, index) => (
                                <Fragment key={request.id}>
                                    {index > 0 && <SmallHeightDivider />}
                                    <RequestCard
                                        title={request.service.name}
                                        percent={request.progress + "%"}
                                        date={request.created_at}
                                        rolBase={rolBase}
                                        company_user_rol={request.user_rol_company}
                                        onClaimModalVisibility={handleClaimModalVisibility}
                                        requestCode={request.code}
                                        citizenID={citizenID}
                                        status={request.status.name}
                                        actionRequired={request.request_actions}
                                        docIdentification={request.doc_identification}
                                        onClick={() =>
                                            history.push(
                                                `/app/serviceRequestedDetails/${request.id}/${request.doc_identification}/${rolBase}/${request.company_id}/${request.company_name}/${request.status.id}`
                                            )
                                        }
                                        statusID={request.status.id}
                                        userName={`${request.user_name} ${request.user_first_last_name} ${request.user_last_name} - ${request.doc_identification}`}
                                        solution={request.solution}
                                        solutionColor={request.status.color}
                                        projectName={request.nmtramite}
                                    />
                                </Fragment>
                            ))}

                            <MediumHeightDivider />
                            {requestsList?.data?.length > 0 ? (
                                <StyledPagination
                                    count={requestsList?.last_page}
                                    page={currentPage}
                                    onChange={(event, page) => {
                                        handlePageChange(page, "personal");
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{ color: COLORS.primary }}
                                />
                            ) : (
                                <SectionTitle>No se encontraron registros</SectionTitle>
                            )}

                            <MediumHeightDivider />
                        </ListContainer>
                    </Fade>
                </TabPanel>
                <TabPanel value="2">
                    <Fade right>
                        <RowMedia style={{ justifyContent: "space-between" }}>
                            <Row style={{ alignItems: "center" }}>
                                <SectionTitle ref={titleRef} style={{ width: 250 }}>
                                    {`Solicitudes ${requestFilterTypes
                                        .find((type) => type.value === Number(requestType))
                                        ?.label?.toLowerCase()}`}
                                </SectionTitle>
                            </Row>
                            <RowMedia style={{ justifyContent: "flex-end" }}>
                                <Select
                                    title={"Filtro por empresa"}
                                    id="filterCompany"
                                    data={requestForCompany.length == 0 ? [finishData] : requestForCompany}
                                    value={requestForCompanyId}
                                    onChange={handleTypeChangeCompany}
                                    disableEmptyValue
                                    marginRight={6}
                                />
                                <Select
                                    title={"por solicitante"}
                                    id="filterSolicitante"
                                    data={requestForApplicant.length == 0 ? [finishData] : requestForApplicant}
                                    value={requestForApplicantId}
                                    onChange={handleTypeChangeApplicant}
                                    disableEmptyValue
                                    marginRight={6}
                                />
                                <Select
                                    title={"por status"}
                                    id="filterType"
                                    data={requestFilterTypes}
                                    value={requestType}
                                    onChange={handleTypeChange}
                                    disableEmptyValue
                                />
                            </RowMedia>
                        </RowMedia>
                        <div style={{ height: "15px" }} />

                        <InputFilter style={{ position: "relative" }}>
                            <TextField
                                placeholder="Nombre o número de solicitud"
                                type="text"
                                id="requestIDCompany"
                                value={requestNameCompany}
                                onChange={(e) => setRequestNameCompany(e.target.value)}
                            />
                            {stringFilterCompany.length > 0 ? (
                                <div style={{ width: "40px", position: "absolute", zIndex: 100, top: 20, right: 10 }}>
                                    <IconButton onClick={() => handleRemoveFilters("company")} aria-label="delete">
                                        <CloseIcon titleAccess="Eliminar filtro" color="error" sx={{ fontSize: "1.2em" }} />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ width: "40px", position: "absolute", zIndex: 100, top: 20, right: 10 }}>
                                    <IconButton onClick={() => handleStringFilter("company")} aria-label="delete">
                                        <SearchIcon titleAccess="buscar" color="action" sx={{ fontSize: "1.2em" }} />
                                    </IconButton>
                                </div>
                            )}
                        </InputFilter>
                        <ListContainer>
                            {requestListByCompany?.data?.map((request, index) => (
                                <Fragment key={request?.id}>
                                    {index > 0 && <SmallHeightDivider />}
                                    <RequestCard
                                        title={request.service.name}
                                        percent={request.progress + "%"}
                                        date={request.created_at}
                                        rolBase={rolBase}
                                        company_user_rol={request.user_rol_company}
                                        onClaimModalVisibility={(value) => handleClaimModalVisibility(value)}
                                        requestCode={request.code}
                                        citizenID={citizenID}
                                        status={request.status.name}
                                        actionRequired={request.request_actions}
                                        docIdentification={request.doc_identification}
                                        handleCompanySelected={setCompanySelected}
                                        code={request.code}
                                        setRequestCode={setRequestCode}
                                        companyID={request.company_id}
                                        companyName={request.company_name}
                                        onClick={() =>
                                            history.push(
                                                `/app/serviceRequestedDetails/${request.id}/${request.doc_identification}/${request.user_rol_company}/${request.company_id}/${request.company_name}/${request.status.id}`
                                            )
                                        }
                                        userName={`${request.user_name} ${request.user_first_last_name} ${request.user_last_name} - ${request.doc_identification}`}
                                        statusID={request.status.id}
                                        solution={request.solution}
                                        solutionColor={request.status.color}
                                        projectName={request.nmtramite}
                                    />
                                </Fragment>
                            ))}

                            <MediumHeightDivider />
                            {requestListByCompany?.data?.length > 0 ? (
                                <StyledPagination
                                    count={requestListByCompany?.last_page}
                                    page={currentPageCompany}
                                    onChange={(event, page) => {
                                        handlePageChange(page, "company");
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{ color: COLORS.primary }}
                                />
                            ) : (
                                <SectionTitle>No se encontraron registros.</SectionTitle>
                            )}

                            <MediumHeightDivider />
                        </ListContainer>
                    </Fade>
                </TabPanel>
            </TabContext>
            <DialogChangeApplicant
                claimModalVisible={claimModalVisible}
                handleClaimModalVisibility={handleClaimModalVisibility}
                companySelected={companySelected}
                searchUser={searchUser}
                deleteFoundUser={deleteFoundUser}
                onClick={handlerChangeOwner}
                title="Cambiar propietario"
                sendButtonLabel={"ENVIAR"}
            />
        </Box>
    );
}

export default MyRequests;
