import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { assingDocumentsForRequiredActionInSoftExpert } from "../../../../api/ActionRequired";
import { linkingDocumentsToRequestInSoftExpert, uploadFormDocuments } from "../../../../api/RequestService";
import TextInformation from "../../../../components/TextInformation/TextInformation";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import { HideGlobalLoading, ShowGlobalLoading } from "../../../../redux/actions/UiActions";
import { SmallHeightDivider, StyledButtonOutlined } from "../../../../theme/Styles";
import { ButtonContainer, Container } from "../../styles/ServiceRequestedDetailsStyles";
import { FileFormSchema } from "./ActionsRequiredConstants";

function ActionsRequired(props) {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    let { requestID } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);
    const userData = queryClient.getQueryData(["userData"]);

    const actionRequiredFileMutation = useMutation(assingDocumentsForRequiredActionInSoftExpert, {
        onMutate: (req) => {
            dispatch(ShowGlobalLoading("Cargando"));
        },
    });

    const fileFormik = useFormik({
        initialValues: {
            file: null,
        },
        validationSchema: FileFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmitFile(values);
        },
    });

    const handleSubmitFile = async (values) => {
        let uploadedFilesRoutes = [];
        const formFileData = new FormData();
        for (let i = 0; i < values.file.files.length; i++) {
            formFileData.append("file[]", values.file.files[i], values.file.files[i].name);
        }
        const uploadFilesConfig = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(ShowGlobalLoading(`Subiendo documentos ${percentCompleted}%`));
            },
        };
        let responseFilesUpload = await uploadFormDocuments(formFileData, uploadFilesConfig);
        if (responseFilesUpload.success) {
            fileFormik.resetForm();
            uploadedFilesRoutes = [
                ...responseFilesUpload.files.map((item, index) => {
                    return {
                        ...item,
                        label: `DOC Accion requerida - ${item.name}`, //item.name //`Documento de accion requerida ${index + 1}`
                    };
                }),
            ];
            //    let uploadSoftExpertArrayAxios = [];
            dispatch(ShowGlobalLoading("Procesando"));
            for (let i = 0; i < uploadedFilesRoutes.length; i++) {
                if (uploadedFilesRoutes[i]?.size_mb < 25) {
                    const isLastIndex = i + 1 === uploadedFilesRoutes.length ? true : false;
                    const softExpertRequest = {
                        documents: [
                            {
                                ...uploadedFilesRoutes[i],
                            },
                        ],
                        title: `documento-${userData.payload.citizen_id}`,
                        record_id: requestData.request.code,
                        attribute: `NumeroSolicitud=${requestData.request.code};DocumentoIdentidadSolicitante=${userData.payload.citizen_id};TipoDocumentoPortal=Adjunto`,
                        process_id: requestData.request.service.process_id,
                        acronym: requestData.direction.acronym + "DE",
                        names: [uploadedFilesRoutes[i].label],
                        required_action_id: props?.data?.id,
                        activity_id: props?.data?.activity_id,
                        new_request: isLastIndex ? false : true,
                    };
                    //uploadSoftExpertArrayAxios.push(linkingDocumentsToRequestInSoftExpert(softExpertRequest));
                    await linkingDocumentsToRequestInSoftExpert(softExpertRequest);
                }
            }
            //  await axios.all(uploadSoftExpertArrayAxios);

            const assignmentData = {
                documents: uploadedFilesRoutes,
                record_id: requestData.request.code,
                status: true,
                required_action_id: props?.data?.id,
            };
            actionRequiredFileMutation.mutate(assignmentData, {
                onSuccess: () => {
                    enqueueSnackbar("Documento requerido enviada satisfactoriamente", { variant: "success" });
                    queryClient.invalidateQueries(["serviceRequestedDetail", requestID]);
                    setTimeout(() => {
                        history.push(`/app/serviceRequestedDetails/${requestID}#documents`);
                        props?.finishActionRequired();
                        dispatch(HideGlobalLoading());
                    }, 500);
                },
                onError: () => {
                    dispatch(HideGlobalLoading());
                    enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
                },
                onSettled: () => {},
            });
        } else {
            dispatch(HideGlobalLoading());
            enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
        }
    };
    return (
        <Container>
            <Fragment>
                <TextInformation title="Documento requerido" />
                <SmallHeightDivider />
                <UploadFile
                    id="file"
                    title="Documento"
                    onChange={fileFormik.handleChange}
                    onBlur={fileFormik.handleBlur}
                    error={fileFormik.touched.file && Boolean(fileFormik.errors.file)}
                    helperText={fileFormik.touched.file && fileFormik.errors.file}
                    required
                    multipleDocuments
                    value={fileFormik.values.file}
                />
                <SmallHeightDivider />
                <ButtonContainer>
                    <StyledButtonOutlined onClick={fileFormik.handleSubmit} variant="outlined">
                        ENVIAR
                    </StyledButtonOutlined>
                </ButtonContainer>
            </Fragment>
        </Container>
    );
}

export default ActionsRequired;
